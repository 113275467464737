'use client';

import { usePathname } from 'next/navigation';

import { BreadcrumbItem, Breadcrumb as BreadcrumbTrilogy, Container } from '@bytel/trilogy-react-ts';

import { StructuredData } from '@components/structured-data';

import { getBreadcrumbStructuredData } from '@helpers/structured-data';

export type BreadcrumbElement = {
    name: string;
    label: string;
    href?: string;
    onClick?: () => void;
};

type BreadcrumbProps = {
    elements: BreadcrumbElement[];
};

export function Breadcrumb({ elements }: Readonly<BreadcrumbProps>) {
    const pathname = usePathname();

    return (
        <Container fluid>
            <StructuredData data={getBreadcrumbStructuredData(pathname, elements)} />
            <BreadcrumbTrilogy data-cy='breadcrumb'>
                {elements.map(({ name, href, label, onClick }, index) => (
                    <BreadcrumbItem
                        key={name}
                        className={index !== elements.length - 1 ? 'is-underline' : ''}
                        href={href}
                        onClick={onClick}
                        active={index === elements.length - 1}
                    >
                        {label}
                    </BreadcrumbItem>
                ))}
            </BreadcrumbTrilogy>
        </Container>
    );
}
