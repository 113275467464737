import { isServer } from '@helpers/config';

export const getClientCookies = (name: string) => {
    if (isServer()) {
        return undefined;
    } else {
        return document.cookie.split('; ').reduce((r, v) => {
            const parts = v.split('=');
            return parts[0] === name ? decodeURIComponent(parts[1] ?? '') : r;
        }, '');
    }
};

export const setClientCookie = (name: string, value: string, days?: number): void => {
    if (!isServer()) {
        let cookieString = `${name}=${encodeURIComponent(value)};path=/`;
        if (days !== undefined) {
            const expires = new Date();
            expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
            cookieString += `;expires=${expires.toUTCString()}`;
        }
        document.cookie = cookieString;
    }
};
