'use client';

import { useSession } from 'next-auth/react';
import { useRouter } from 'next/navigation';
import React, { MouseEvent, useEffect, useState } from 'react';

import { classNames } from '@bytel/product-wall/helpers';
import {
    Alignable,
    Box,
    BoxContent,
    Button,
    Icon,
    IconColor,
    IconName,
    Justifiable,
    Link,
    Loader,
    Modal,
    Radio,
    Spacer,
    SpacerSize,
    Tag,
    TagVariant,
    Text,
    TypographyBold,
    VariantState,
    View,
} from '@bytel/trilogy-react-ts';
import { OnClickEvent } from '@bytel/trilogy-react-ts/src/events/OnClickEvent';

import { logger } from '@services/loggers';
import { removeLocalVariable } from '@services/storage';

import { getContracts } from '@actions/contracts';

import { ContractType } from '@app-types/contract';
import { UserType } from '@app-types/user';

import { paths } from '@helpers/path';
import { formatPhoneNumber } from '@helpers/phone-number';

function ContractSelectionItem({ contract }: { contract: ContractType; isSelected: boolean }) {
    return (
        <div className='has-text-left'>
            <Text marginless className={classNames('subtitle', !contract.isRenewalEligible ? 'has-text-grey' : '')}>
                {formatPhoneNumber(contract.phoneNumber)}
            </Text>
            <Text marginless typo={TypographyBold.TEXT_WEIGHT_NORMAL}>
                {contract.mobile ? `${contract.mobile.brand} ${contract.mobile.name}` : 'Mobile non identifié'}
            </Text>
            <Text marginless typo={TypographyBold.TEXT_WEIGHT_NORMAL}>
                {contract.subscription.label}
            </Text>
            {!contract.isRenewalEligible && (
                <Text>
                    <Tag variant={TagVariant.ERROR} className='is-size-6 has-text-grey'>
                        <Icon
                            name={IconName.TIMES_CIRCLE}
                            color={IconColor.ERROR}
                            content='Ligne non éligible'
                            className='icon-not-eligible'
                        />
                    </Tag>
                </Text>
            )}
        </div>
    );
}

type ContractSelectionModalProps = {
    onClose?: () => void;
    selected?: ContractType['id'];
    redirect?: string;
};

export function ContractSelectionModal({
    onClose,
    selected,
    redirect = paths.phoneWall,
}: Readonly<ContractSelectionModalProps>) {
    const { data: session } = useSession();
    const user = session?.user as UserType;
    const router = useRouter();

    const [isLoading, setIsLoading] = useState(true);
    const [signedContracts, setSignedContracts] = useState<ContractType[]>([]);
    const [selectedContract, setSelectedContract] = useState<ContractType['id'] | undefined>(selected);

    const setContractCookie = (id: string) => {
        document.cookie = `oauth2_contract_id=${id};path=/;max-age=86400`;
    };

    const handleSelectedItem = (contract: ContractType) => {
        setSelectedContract(contract.id);
        setContractCookie(contract.id);
    };

    useEffect(() => {
        if (!user) {
            return;
        }
        if (user.login) {
            // If the CDC user comes to the renewal page without a contractId, we redirect him to the acquisition wall
            if (user.user_type === 'CDC') {
                router.push(redirect);
                return;
            }

            setIsLoading(true);
            getContracts(user.sub)
                .then((newSignedContracts) => {
                    // If the user has no signed contracts, we redirect him to the acquisition wall
                    if (newSignedContracts.length <= 0) {
                        router.push(redirect);
                        return;
                    }

                    const eligibleContracts = newSignedContracts.filter((contract) => contract.isRenewalEligible);

                    // If the user has only one signed contract and it is eligible, we select it
                    if (newSignedContracts.length === 1 && eligibleContracts.length === 1 && !selected) {
                        const { id } = newSignedContracts[0] as ContractType;
                        setContractCookie(id);
                        window.location.href = `?contractId=${id}`;
                        return;
                    }

                    // If the user has no selected contract and has at least one eligible contract, we select the first one
                    if (!selected && eligibleContracts.length > 0) {
                        setSelectedContract(eligibleContracts[0]?.id);
                    }

                    setSignedContracts(newSignedContracts);
                    setIsLoading(false);
                })
                .catch((error) => {
                    logger.error(error);
                    setIsLoading(false);
                });
        }
    }, [user?.sub]);

    useEffect(() => {
        setSelectedContract(selected);
    }, [selected]);

    if (!user) {
        return null;
    }

    return (
        <Modal active disableHandlingClickOutside closeIcon={selected !== undefined} onClose={onClose}>
            <div className='modal-title'>
                Bonjour <span className='is-capitalized'>{user.given_name.toLowerCase()}</span>
            </div>
            <Text>Sélectionnez la ligne concernée par le changement de mobile</Text>
            {isLoading && (
                <View flexable align={Alignable.ALIGNED_CENTER} justify={Justifiable.JUSTIFIED_CENTER}>
                    <Loader />
                </View>
            )}
            {!isLoading && (
                <>
                    <div>
                        {signedContracts.map((signedContract) => {
                            return (
                                <Box
                                    key={signedContract.id}
                                    className={
                                        selectedContract === signedContract.id
                                            ? 'border-box-option-selected'
                                            : 'border-box-option'
                                    }
                                >
                                    <BoxContent>
                                        <Radio
                                            id={signedContract.id}
                                            disabled={!signedContract.isRenewalEligible}
                                            onClick={() => handleSelectedItem(signedContract)}
                                            checked={selectedContract === signedContract.id}
                                            value={signedContract.id}
                                            className='is-fullwidth'
                                            label={
                                                <ContractSelectionItem
                                                    contract={signedContract}
                                                    isSelected={selectedContract === signedContract.id}
                                                />
                                            }
                                        />
                                    </BoxContent>
                                </Box>
                            );
                        })}
                    </div>
                    <Spacer size={SpacerSize.MEDIUM} />
                    <View
                        flexable
                        align={Alignable.ALIGNED_CENTER}
                        justify={Justifiable.JUSTIFIED_CENTER}
                        className='is-vertical'
                    >
                        {signedContracts.length > 0 && (
                            <>
                                <a
                                    autoFocus
                                    aria-disabled={!selectedContract}
                                    href={selectedContract ? `?contractId=${selectedContract}` : 'javascript:void(0)'}
                                >
                                    <Button
                                        variant={VariantState.PRIMARY}
                                        disabled={!selectedContract}
                                        onClick={(e: OnClickEvent) => {
                                            const event = e as MouseEvent<HTMLButtonElement>;

                                            if (!selectedContract) {
                                                event.preventDefault();
                                                return;
                                            }

                                            // If the contract change, we clear the cart
                                            if (selectedContract !== selected) {
                                                removeLocalVariable('checkout_cart');
                                            }

                                            onClose?.();
                                        }}
                                    >
                                        Valider
                                    </Button>
                                </a>
                                Ou
                            </>
                        )}
                        <Link href={redirect}>Ouvrir une nouvelle ligne</Link>
                    </View>
                </>
            )}
        </Modal>
    );
}
